<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">CREATE </span> FEES
    </PageHeader>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form ref="feesForm" @submit.prevent>
              <v-row align="center" justify="center">
                <v-col cols="12" lg="6">
                  <v-card outlined>
                    <v-toolbar
                      elevation="0"
                      color="primary"
                      class="secondary--text"
                    >
                      <v-toolbar-title>Calendar and Program</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            class="rounded-0"
                            hide-details="auto"
                            :menu-props="{ down: true, offsetY: true }"
                            outlined
                            :items="basket_calendar"
                            item-text="academic_calendar"
                            item-value="id"
                            label="Calendar"
                            :error-messages="error_msgs"
                            loader-height="2"
                            :loading="chkfees"
                            v-model="calendar"
                            :rules="[
                              (v) => !!v || 'Academic Calendar is required',
                            ]"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            hide-details="auto"
                            :error-messages="error_msgs"
                            class="rounded-0"
                            outlined
                            :items="getters_program"
                            item-text="program_name_certificate"
                            item-value="id"
                            label="Program"
                            :loading="chkfees"
                            v-model="program"
                            :rules="[(v) => !!v || 'Program is required']"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" lg="3">
                  <v-card outlined>
                    <v-toolbar
                      elevation="0"
                      color="primary"
                      class="secondary--text"
                    >
                      <v-toolbar-title>Fresh Students</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            class="rounded-0"
                            outlined
                            label="New Gh Local Student"
                            placeholder="New Gh Local Student"
                            v-model.number="fresh_student_fees_local"
                            :rules="[(v) => v > 0 || 'Fees is required']"
                            loader-height="2"
                            type="number"
                            min="0"
                            prepend-inner-icon="₵"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            class="rounded-0"
                            outlined
                            label="New Gh Foreign Student"
                            v-model.number="fresh_student_fees_foreign"
                            :rules="[(v) => v > 0 || 'Fees is required']"
                            type="number"
                            min="0"
                            prepend-inner-icon="₵"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" lg="3">
                  <v-card outlined>
                    <v-toolbar
                      elevation="0"
                      color="primary"
                      class="secondary--text"
                    >
                      <v-toolbar-title>Continuing Students</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            class="rounded-0"
                            outlined
                            label="New Gh Local Student"
                            placeholder="New Gh Local Student"
                            v-model.number="continuing_student_fees_local"
                            :rules="[(v) => !!v || 'Fees is required']"
                            hide-details="auto"
                            loader-height="2"
                            type="number"
                            min="0"
                            prepend-inner-icon="₵"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            class="rounded-0"
                            hide-details="auto"
                            label="New Gh Foreign Student"
                            placeholder="New Gh Foreign Student"
                            v-model.number="continuing_student_fees_foreign"
                            :rules="[(v) => v > 0 || 'Fees is required']"
                            type="number"
                            min="0"
                            prepend-inner-icon="₵"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- 	<p v-show="showDeadline" class="text-uppercase grey--text mt-5">
								DEADLINES
							</p>
							<v-row>
								<v-col>
									<v-slide-y-transition color="primary" group>
										<v-card
											outlined
											v-show="showDeadline"
											class="py-1 my-1"
											:style="`border-left: 5px solid #0288D1`"
											v-for="(deadline, i) in basketDeadline"
											:key="i"
										>
											<v-list-item
												active-class="false"
												:ripple="false"
												:key="i"
											>
												<v-row align="center" justify="center">
													<v-col cols="12" lg="1">
														<v-avatar size="30" color="primary">
															<span class="white--text headline">{{
																i + 1
															}}</span>
														</v-avatar>
													</v-col>

													<v-col lg="4">
														<v-menu
															v-model="deadline.menu2"
															:close-on-content-click="false"
															:nudge-right="40"
															transition="scale-transition"
															offset-y
															min-width="auto"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field
																	readonly
																	dense
																	v-model="deadline.date"
																	clearable
																	label="Date"
																	:error-messages="deadline.error"
																	hide-details="auto"
																	hide-selected
																	v-bind="attrs"
																	v-on="on"
																	outlined
																	:rules="[(v) => !!v || 'Date is required...']"
																>
																</v-text-field>
															</template>
															<v-date-picker
																v-model="deadline.date"
																@input="deadline.menu2 = false"
																@change="ifdeadlineExist"
															></v-date-picker>
														</v-menu>
													</v-col>
													<v-col cols="12" lg="2">
														<v-text-field
															@change="ifdeadlineExist"
															outlined
															dense
															hide-details="auto"
															label="Percentage"
															append-icon="mdi-percent"
															type="number"
															max="100"
															v-model.number="deadline.percent"
															:rules="[
																(v) => !!v || 'Deadline is required...',
																(v) =>
																	(v > 0 && v <= 100) ||
																	'Percentage should be greater than zero but less than 100',
															]"
														></v-text-field>
													</v-col>

													<v-col cols="12" lg="1">
														<div class="text-center">
															<v-btn
																v-if="i + 1 >= 2"
																dark
																icon
																large
																color="red"
																@click="spliceDeadline(i)"
															>
																<v-icon large dark> mdi-close-circle </v-icon>
															</v-btn>
														</div>
													</v-col>
													<v-col cols="12" lg="1">
														<div class="text-center">
															<v-btn
																icon
																dark
																v-if="i + 1 === basketDeadline.length"
																color="success"
																@click="newRow()"
															>
																<v-icon large dark>mdi-plus-circle</v-icon>
															</v-btn>
														</div>
													</v-col>
												</v-row>
											</v-list-item>
										</v-card>
									</v-slide-y-transition>
								</v-col>
							</v-row>
 -->
              <v-row v-show="showDeadline" align="center" justify="center">
                <v-col cols="4">
                  <v-card flat>
                    <v-card-actions>
                      <v-btn
                        block
                        :disabled="clickOnce"
                        @click.enter="saveFeesBtn"
                        v-if="getters_abilities.includes('create_fees_access')"
                        large
                        class="mx-2"
                        color="primary"
                      >
                        save fees
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-overlay :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-container>
</template>

<script>
  import { computed, reactive, ref, toRefs, watch } from "vue";
  import debounce from "@/debounce/debounce";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Error from "@/components/Error/Error";
  import PageHeader from "@/components/slots/PageHeader";

  export default {
    components: { Error, PageHeader },
    setup(props, ctx) {
      const {
        saveFees,
        getFees,
        signIn,
        removeFees,
        getCurCalendar,
        getProgram,
        duplicateFees,
        saveFeesChanges,
      } = useActions([
        "saveFees",
        "getFees",
        "signIn",
        "removeFees",
        "getCurCalendar",
        "getProgram",
        "duplicateFees",
        "saveFeesChanges",
      ]);

      const {
        getters_fees,
        user,
        getters_feesalreadyexist,
        getters_curcalendar,
        getters_program,
        getters_abilities,
      } = useGetters([
        "getters_fees",
        "user",
        "getters_feesalreadyexist",
        "getters_curcalendar",
        "getters_program",
        "getters_abilities",
      ]);

      const Fees = reactive({
        feeArray: ["local", "foreign"],
      });

      const feesForm = reactive({
        basket_calendar: [],
        chkfees: false,
        fresh_student_fees_local: null,
        fresh_student_fees_foreign: null,
        continuing_student_fees_local: null,
        continuing_student_fees_foreign: null,
        calendar: new Date().toISOString().substr(0, 10),
        program: null,
        error_msgs: "",
        basket: {},
        clickOnce: false,
        loading: false,
        absolute: true,
        overlay: false,
        basketDeadline: [
          {
            date: new Date().toISOString().substr(0, 10),
            error: "",
            percent: 0,
            menu2: false,
          },
        ],
        showDeadline: true,
        errormsgsDeadline: [],
        deadlineBasket: [],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
      });

      const editFeesForm = reactive({
        isLoaded: true,
        isadmin: true,
        adminPassword: "",
        adminResponse: false,
        headers: [
          { text: "#", value: "index" },
          {
            text: "ACADEMIC CALENDAR",
            value: "academic_calendar",
          },
          {
            text: "PROGRAM",
            value: "fullprogram",
          },
          {
            text: "FRESH STUDENT FEES",
            value: "fresher_fees_local_foreign",
            align: "center",
          },
          {
            text: "CONTINUING STUDENT FEES",
            value: "continuing_fees_local_foreign",
            align: "center",
          },
          //	{ text: "DEADLINE(S)", value: "deadline" },

          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        editedFees: {
          edited_fresh_student_fees_local: 0,
          edited_fresh_student_fees_foreign: 0,
          edited_continuing_student_fees_local: 0,
          edited_continuing_student_fees_foreign: 0,
        },
      });

      let menu3 = ref(false);

      let { isadmin, adminPassword, isLoaded, adminResponse } =
        toRefs(editFeesForm);
      let {
        msgHeader,
        msgBody,
        msgIcon,
        deleteResponse,
        basket_calendar,
        chkfees,
        fresh_student_fees_local,
        fresh_student_fees_foreign,
        continuing_student_fees_local,
        continuing_student_fees_foreign,
        basket,
        clickOnce,
        loading,
        error_msgs,
        calendar,
        program,
        overlay,
        basketDeadline,
        showDeadline,
        errormsgsDeadline,
        deadlineBasket,
      } = toRefs(feesForm);

      const spliceDeadline = (i) => {
        errormsgsDeadline.value = "";
        basketDeadline.value.splice(i, 1);
      };

      const newRow = () => {
        if (ctx.refs.feesForm.validate()) {
          let total = basketDeadline.value.reduce(
            (acc, item) => acc + item.percent,
            0
          );

          basketDeadline.value.push({
            date: null,
            error: "",
            percent: 100 - total,
            menu2: false,
          });
        }
      };

      const ifdeadlineExist = () => {
        let thisbasket = [];
        basketDeadline.value.forEach((row) => {
          if (new Date(row.date) <= new Date()) {
            row.error = "Date should be greater than today";
            errormsgsDeadline.value.push(row.error);
            return;
          } else {
            row.error = "";
            let bool = thisbasket.some((item_row) => {
              return new Date(row.date) < new Date(item_row.deadline);
            });
            if (bool) {
              row.error = "Date should be greater than the date(s) provided";
              errormsgsDeadline.value.push(row.error);
              return;
            }
          }

          if (!deadlineBasket.value.includes(row.date)) {
            if (row.percent > 0) {
              thisbasket.push({ deadline: row.date, percent: row.percent });
              deadlineBasket.value = thisbasket;
              row.error = "";
              errormsgsDeadline.value = [];
            }
          } else {
            row.error = "duplicate found";
            errormsgsDeadline.value.push(row.error);
          }
        });
      };

      const removeError = () => {
        if (adminPassword.value == "") {
          ctx.refs.editFeesForm.resetValidation();
        }
      };

      const ifAdmin = debounce(() => {
        if (adminPassword.value == "") {
          adminResponse.value = false;
          return;
        }

        adminResponse.value = true;
        isadmin.value = true;
        if (ctx.refs.editFeesForm.validate()) {
          let credentials = {
            email: user.value.email,
            password: adminPassword.value,
          };
          signIn(credentials)
            .then(() => {
              isadmin.value = false;
              adminResponse.value = false;
            })
            .catch(() => {
              adminResponse.value = false;
            });
        }
      });

      const removeFeesBtn = (id) => {
        removeFees(id);
      };

      getFees().then(() => {
        isLoaded.value = false;
      });

      watch(
        () => [
          calendar.value,
          fresh_student_fees_local.value,
          fresh_student_fees_foreign.value,
          continuing_student_fees_local.value,
          continuing_student_fees_foreign.value,
          program.value,
        ],
        () => {
          if (
            calendar.value &&
            fresh_student_fees_local.value &&
            fresh_student_fees_foreign.value &&
            continuing_student_fees_local.value &&
            continuing_student_fees_foreign.value &&
            program.value
          ) {
            showDeadline.value = true;
          }
        }
      );

      const saveFeesBtn = () => {
        clickOnce.value = true;

        basket.value = {
          fresh_student_fees_local: fresh_student_fees_local.value,
          fresh_student_fees_foreign: fresh_student_fees_foreign.value,
          continuing_student_fees_local: continuing_student_fees_local.value,
          continuing_student_fees_foreign: continuing_student_fees_foreign.value,
          calendar_id: calendar.value,
          program_id: program.value,
          enrolment_deadline: deadlineBasket.value,
        };
        if (
          ctx.refs.feesForm.validate() /* &&
              				errormsgsDeadline.value.length === 0 */
        ) {
          overlay.value = true;
          loading.value = true;
          saveFees(basket.value)
            .then(() => {
              loading.value = false;
              clickOnce.value = false;
              overlay.value = false;
              error_msgs.value = "";
              ctx.refs.feesForm.reset();
              calendar.value = getters_curcalendar.value[0].id;
            })

            .catch((e) => {
              overlay.value = false;
              ctx.refs.feesForm.reset();
              loading.value = false;

              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              if (e.response.status === 403) {
                deleteResponse.value = true;
                msgBody.value = "This action is unauthorized";
              } else {
                error_msgs.value = "Try Again Later";
              }
            });
        }
        clickOnce.value = false;
      };

      watch(
        () => [program.value, calendar.value],
        () => {
          if (program.value != null) {
            chkfees.value = true;
            const basket = {
              program_id: program.value,
              calendar_id: calendar.value,
            };

            duplicateFees(basket)
              .then(() => {
                error_msgs.value = "";
                chkfees.value = false;
              })
              .catch(() => {
                chkfees.value = false;
                error_msgs.value = "Fees Already Exists";
              });
          }
        }
      );

      getCurCalendar().then(() => {
        basket_calendar.value = getters_curcalendar.value;
        calendar.value = getters_curcalendar.value[0].id;
      });

      getProgram();

      const basketWithIndex = computed(() => {
        return getters_fees.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      const saveProgramFeesChangesBtn = (id, item) => {
        saveFeesChanges({ id: id, itemfees: item });
      };

      return {
        ...toRefs(feesForm),
        ...toRefs(editFeesForm),
        saveFeesBtn,
        basketWithIndex,
        ifAdmin,
        removeFeesBtn,
        ...toRefs(Fees),
        basket_calendar,
        getters_program,
        getters_feesalreadyexist,
        spliceDeadline,
        newRow,
        ifdeadlineExist,
        menu3,
        removeError,
        saveProgramFeesChangesBtn,
        getters_abilities,
      };
    },
  };
</script>
<style>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }
</style>
